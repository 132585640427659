<template>
    <div class="store-wrapper">
        <el-scrollbar class="store-container" :native="false">
            <div class="web-top-wrapper">
                <div class="web-top">
                    <div class="top-left">
                        <!--                        <div class="web-logo">-->
                        <!--                            <img :src="require('../../../assets/images/jd-logo.png')" alt="">-->
                        <!--                        </div>-->
                        <el-select v-model="classValue" placeholder="全部分类" style="width: 110px">
                            <el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="top-search">
                        <el-input v-model="search" placeholder="请输入搜索内容" style="width: 480px;"></el-input>
                        <div class="btn search-all">搜全站</div>
                        <div class="btn search-current">搜本站</div>
                    </div>
                </div>
            </div>
            <div class="web-tab-wrapper">
                <div class="web-tab" v-if="this.$route.query.type === '1'">{{goodInfo.category_id[0]}}&nbsp;>&nbsp;{{goodInfo.category_id[1]}}&nbsp;>&nbsp;{{goodInfo.category_id[2]}}</div>
                <div class="web-tab" v-else >{{goodInfo.category_id[1]}}&nbsp;>&nbsp;{{goodInfo.category_id[3]}}&nbsp;>&nbsp;{{goodInfo.category_id[5]}}</div>
            </div>
            <div class="good-info-wrapper">
                <div class="good-info">
                    <div class="good-cover">
                        <swiper ref="mySwiper" :options="swiperOptions" class="store-banner">
                            <swiper-slide class="item" v-for="(imgItem, imgIndex) in goodInfo.good_images" :key="`goods_image_${imgIndex}`">
                                <img :src="imgItem">
                            </swiper-slide>
                            <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                        <!--                        <div class="swiper-container" v-swiper:mySwiper="swiperOptions">-->
                        <!--                            <div class="swiper-wrapper">-->
                        <!--                                <div class="swiper-slide"-->
                        <!--                                     v-for="(imgItem, imgIndex) in goodInfo.goods_image" :key="`goods_image_${imgIndex}`">-->
                        <!--                                    <img :src="imgItem.image_url">-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="swiper-pagination"></div>-->
                    </div>
                    <div class="right-content">
                        <p class="good-title">{{goodInfo.good_title}}</p>
                        <div class="good-price">
                            <div class="price">
                                <span class="text">市场价</span>
                                <span class="num">&yen;{{goodInfo.market_price}}</span>
                            </div>
                            <div class="sales-price">
                                <span class="text">销售价</span>
                                <span class="num">&yen;{{goodInfo.sales_price}}</span>
                            </div>
                        </div>
                        <div class="good-address">
                            <span class="text">配送   至</span>
                            <el-select v-model="addressList" placeholder="请选择地区" style="width: 110px" size="mini">
                                <el-option label="全部地区" value="1"></el-option>
                                <!--                                <el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
                            </el-select>
                            <span class="text">有货</span>
                            <span class="text">免运费</span>
                        </div>
                        <div class="good-color">
                            <span class="text">选择颜色</span>
                            <div class="color-content">
                                <div class="color-img">
                                    <img :src="goodInfo.good_images.length > 0 ? goodInfo.good_images[0] : require('../../assets/images/noimg.png')" alt="">
                                </div>
                                <div class="color-text">颜色</div>
                            </div>
                        </div>
                        <div class="good-color">
                            <span class="text">商品货号</span>
                            <span class="text" style="margin-left:10px" >{{goodInfo.goods_code}}</span>
                        </div>
                        <div class="good-color">
                            <span class="text">商品库存</span>
                                <span class="text" style="margin-left:10px" >{{goodInfo.stock}}</span>
                        </div>
                        <div class="good-num-btn">
                            <div class="good-num">
                                <el-input-number v-model="good_num" size="small" :min="1" label="描述文字" controls-position="right" class="good-num-input"></el-input-number>
                            </div>
                            <div class="good-btn">
                                <el-button class="btn-red-dark">抢购</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrapper" style="margin-top: 30px">
                <div class="web-tab-wrapper">
                    <div class="web-tab">
                        <div class="tab-item">商品属性</div>
                    </div>
                </div>
                <div class="detail-content" v-if="this.$route.query.type == '1'">
                    <div class="good-property">
                        <div class="item" v-for="item in goodInfo.good_property">
                            <span>{{item.name}}：</span>
                            <span v-if="item.content.length <= 15">{{item.content}}</span>
                            <el-tooltip effect="dark" :content="item.content" placement="top-start" v-else>
                                <span>{{ item.content.slice(1, 15) + '...' }}</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="detail-content" v-else>
                    <div class="good-property">
                        <div class="item" v-for="item in goodInfo.goods_attribute">
                            <span>{{item.attribute}}：</span>
                            <span v-if="item.content.length <= 15">{{item.content}}</span>
                            <el-tooltip effect="dark" :content="item.content" placement="top-start" v-else>
                                <span>{{ item.content.slice(1, 15) + '...' }}</span>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-wrapper" style="margin-top: 10px">
                <div class="web-tab-wrapper">
                    <div class="web-tab">
                        <div class="tab-item">商品介绍</div>
                    </div>
                </div>
                <div class="detail-content" v-if="this.$route.query.type == '1'">
                    <!-- <div class="good-property">
                        <div class="item" v-for="item in goodInfo.good_property">
                            <span>{{item.name}}：</span>
                            <span>{{item.content}}</span>
                        </div>
                    </div> -->
                    <div class="good-details" v-html="goodInfo.good_details"></div>
                </div>  
                <div class="detail-content" v-else>
                    <div class="good-property">
                        <div class="item" v-for="item in goodInfo.good_property">
                            <span>{{item.name}}：</span>
                            <span v-if="item.content.length <= 15">{{item.content}}</span>
                            <el-tooltip effect="dark" :content="item.content" placement="top-start" v-else>
                                <span>{{ item.content.slice(1, 15) + '...' }}</span>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="good-details" v-html="goodInfo.good_details"></div>
                </div>  
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import { stuOpStuOpHotGoodsDetail, stuOpStuOpGoodsDetail} from '@/utils/apis.js'
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        data() {
            return {
                role: '',//角色id
                goodsId: Number(this.$route.query.g_id) || null,
                search: '',
                classList: [
                    {
                        value: '1',
                        label: '全部分类'
                    }
                    ],
                classValue: '全部分类',
                goodInfo: {
                    id: null,
                    good_title: '',
                    good_images: [],
                    market_price: '',
                    sales_price: '',
                    category_id: [],
                    good_details: ''
                },
                good_num: 1,
                addressList: '请选择地区',
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        renderBullet: (index, className) => {
                            if (this.goodInfo.good_images.length === 0) {
                                return ''
                            }
                            return `<span class="${className}"><img src="${this.goodInfo.good_images[index]}"></span>`
                        },
                    },
                },
            }
        },
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        mounted() {
            this.role = localStorage.getItem('role');
            this.getGoodsInfo();
        },
        created() {
        },
        methods: {
            //请求商品详情
            getGoodsInfo(){
                let params = {
                    id: this.goodsId
                }
                if (this.$route.query.type === '1') {
                    stuOpStuOpHotGoodsDetail(params).then((res) => {
                        this.goodInfo = res.data;
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    stuOpStuOpGoodsDetail(params).then((res) => {
                        console.log(124)
                        this.goodInfo = {
                            id: res.data.id,
                            good_title: res.data.goods_title,
                            good_images: res.data.goods_images,
                            market_price: res.data.market_price,
                            sales_price: res.data.sale_price,
                            category_id: res.data.goods_category_id,
                            good_details: res.data.goods_details,
                            goods_attribute:res.data.goods_attribute,
                            goods_code:res.data.goods_code,
                            stock:res.data.stock
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .store-wrapper {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: #fff;
        overflow-y:auto;
    }
    .store-container {
        /*min-width: 1200px;*/
        height: 100vh;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                min-width: 1200px;
            }
        }
        .web-top-wrapper {
            .web-top {
                width: 1200px;
                height: 106px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .top-left {
                    display: flex;
                    .web-logo {
                        width: 134px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 60px;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    ::v-deep .el-select {
                        /*margin-left: 60px;*/
                        .el-input__inner {
                            border-radius: 0;
                        }
                    }
                }
                ::v-deep .top-search {
                    display: flex;
                    align-items: center;
                    .el-input {
                        .el-input__inner {
                            border-radius: 0;
                            border-width: 2px 0 2px 2px;
                            border-color: #DF0200;
                            border-style: solid;
                        }
                    }
                    .btn {
                        width: 60px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        color: #fff;
                        cursor: pointer;
                        &.search-all {
                            background: #DF3033;
                        }
                        &.search-current {
                            background: #474E5C;
                        }
                    }
                }
            }
        }
        .web-tab-wrapper {
            background: #F2F2F2;
            .web-tab {
                width: 1200px;
                margin: 0 auto;
                height: 40px;
                line-height: 40px;
                color: #666;
                font-size: 14px;
                font-weight: 500;
                .tab-item {
                    color: #fff;
                    background: #DF3033;
                    width: 108px;
                    text-align: center;
                }
            }
        }
        .good-info-wrapper {
            margin-top: 20px;
            .good-info {
                width: 1200px;
                margin: 0 auto;
                display: flex;
                .good-cover {
                    position: relative;
                    .swiper-container {
                        width: 350px;
                        height: 450px;
                        .swiper-slide {
                            width: 350px!important;
                            /*height: 560px;*/
                            height: 350px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: #fafafa;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                                /*width: 100%;*/
                                /*height: 100%;*/
                            }
                        }
                    }
                    ::v-deep .swiper-pagination {
                        width: 350px;
                        display: flex;
                        margin: 20px -5px;
                        bottom: 0;
                        .swiper-pagination-bullet {
                            /*width: 96px;*/
                            width: 60px;
                            height: 60px;
                            background: #fafafa;
                            border: 2px solid transparent;
                            box-sizing: border-box;
                            margin: 0 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 1;
                            border-radius: 0;
                            img {
                                display: block;
                                max-width: 100%;
                                max-height: 100%;
                            }
                            &.swiper-pagination-bullet-active {
                                border-color: #DF3033;
                            }
                        }
                    }
                }
                .right-content {
                    margin-left: 40px;
                    width: 1%;
                    flex: 1;
                    .good-title {
                        font-size: 24px;
                        font-weight: bold;
                        color: #333;
                    }
                    .good-price {
                        margin-top: 20px;
                        background: #F7F7F7;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        padding-left: 16px;
                        .price {
                            color: #999;
                            .text {
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .num {
                                margin-left: 10px;
                                font-size: 24px;
                                text-decoration: line-through;
                                font-weight: 500;
                            }
                        }
                        .sales-price {
                            margin-left: 30px;
                            .text {
                                color: #999;
                                font-size: 18px;
                                font-weight: bold;
                            }
                            .num {
                                margin-left: 10px;
                                color: #FF0000;
                                font-size: 24px;
                                font-weight: 500;
                            }
                        }
                    }
                    .good-address {
                        margin-top: 20px;
                        color: #999;
                        .text {
                            margin-right: 20px;
                            font-size: 18px;
                            font-weight: 500;
                        }
                        .el-select {
                            margin-right: 20px;
                        }
                    }
                    .good-color {
                        margin-top: 20px;
                        display: flex;
                        .text {
                            color: #999;
                            font-size: 18px;
                            font-weight: 500;
                        }
                        .color-content {
                            flex: 1;
                            width: 1%;
                            margin-left: 20px;
                            display: flex;
                            .color-img {
                                width: 40px;
                                height: 40px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: 1px solid #DF3033;
                                box-sizing: border-box;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .color-text {
                                margin-left: -1px;
                                border: 1px solid #DF3033;
                                box-sizing: border-box;
                                background: #F7F7F7;
                                width: 68px;
                                height: 40px;
                                text-align: center;
                                line-height: 40px;
                                font-size: 18px;
                                font-weight: 500;
                                color: #333;
                            }
                        }
                    }
                    .good-num-btn {
                        display: flex;
                        align-items: center;
                    }
                    .good-num {
                        margin-top: 20px;
                        .good-num-input {
                            ::v-deep .el-input-number__decrease {
                                line-height: 30px;
                            }
                            ::v-deep .el-input-number__increase {
                                line-height: 30px;
                            }
                            ::v-deep .el-input .el-input__inner {
                                height: 60px;
                                line-height: 60px;
                            }
                        }
                    }
                    .good-btn {
                        margin-top: 20px;
                        margin-left: 20px;
                        ::v-deep .el-button {
                            width: 94px;
                            height: 60px;
                            background: #DF3033;
                            border-radius: 2px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .content-wrapper {
            overflow-y:auto;
            .web-tab-wrapper {
                background: #F2F2F2;
                .web-tab {
                    width: 1200px;
                    margin: 0 auto;
                    .tab-item {
                        width: 120px;
                        line-height: 40px;
                        background: #DF3033;
                        text-align: center;
                        color: #fff;
                    }
                }
            }
            .detail-content {
                width: 1200px;
                margin: 10px auto;
                .good-property {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .item {
                        width: 25%;
                        font-size: 18px;
                        color: #666;
                        margin: 10px 0;
                    }
                }
                .good-details {
                    width: 100%;
                    text-align: center;
                    ::v-deep p {
                        img {
                            max-width: 100%;
                            display: inline-block;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    ::v-deep p {
        margin: 0;
    }
    .btn-red-dark {
        width: 140px;
        height: 46px;
        border-radius: 0;
        background: #DF3033;
        border-color: #DF3033;
        color: #fff;
    }
</style>